.tag__container{
    color: #080C1E;
    font-size: 14px;
    background-color: #F7F9FB;
    border-radius: 6px;
    padding: 4px 8px;
    font-family: Satoshi-Medium!important;
}

.tag__bordered{
    background-color: rgba(0,0,0,0);
    border: 1px solid #EDF1F9;
}