*{
    font-family: "Satoshi-Bold"!important;
    --primary-color: #7280E4;
    --text-primary-color: #5C5E64;
    --secondary-color: #F7F9FB;
    --border-color: #EDF1F9;
    --icon-fill: var(--text-primary-color);

    /* color: var(--text-primary-color); */
}

body, html {
    background-color: var(--secondary-color);
}

input{
    outline: none;
    border: 1px solid #EDF1F9;
    padding: 16px;
    font-family: Satoshi-Medium!important;
    width: calc(100% - 32px);
    border-radius: 12px;
    color: #080C1E;
}
input:focus{
    border: 2px solid #7C93F7;
}
input::placeholder{
    font-family: Satoshi-Medium!important;
    color: #C1C2C6!important;
}

button{
    background-color: var(--secondary-color);
    border: 2px solid var(--border-color);
    color: var(--text-primary-color);
    cursor: pointer;
}
button.primary{
    color: white;
    background-color: #7C93F7;
    padding: 16px 64px;
    border-radius: 12px;
}
button.secondary{
    background-color: #F7F9FB;
    color: #5C5E64;
    border: 2px solid #EDF1F9;
    padding: 16px 64px;
    border-radius: 12px;
}

.layout__container{
    padding-top: 30px;
    height: calc(100vh - 30px);
    overflow-y: scroll;
    padding-left: 40px;
}

.header{
    color: black;
    font-size: 32px;
}

.file__screen{
    padding-top: 20px;
}

button.disabled{
    background-color: #EEF0F2!important;
    color: #C1C2C6!important;
}

/* .chart-with-header{
    border: 2px solid #EDF1F9;
    border-radius: 12px;
} */

.chart-with-header__header{
    margin-top: 30px;
    color: #080C1E!important;
    font-family: Satoshi-Medium!important;
}

.silent{
    opacity: 0;
}

.charts{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.stats{
    padding-top: 20px;
    align-items: end!important;
}

.create-new-key{
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.footer__btns{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.modal__container{
    padding-bottom: 40px;
    padding-top: 20px;
}



.modal__text{
    font-family: Satoshi-Medium!important;
    color: #5C5E64;
    font-size: 18px;
}

.modal__text-container{
    padding-bottom: 40px;
}

.fz16{
    font-size: 16px;
}

.api-key__text{
    color: #080C1E;
}

.api-key_text__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #F7F9FB;
    padding-bottom: 10px;
    padding-top: 30px;
}

.header__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.centered-layout{
    display: flex;
    flex-direction: column;
    justify-content: start;
    /* align-items: center; */
    width: calc(100vw - 298px + 40px);
    padding-left: 0px!important;
}

.editable-britishizer{
    font-family: Satoshi-Regular!important;
}
.source .word {
    background-color: rgba(245,61,61,.15) !important;
    border-radius: 2px;
}
.source .syms {
    background-color: rgba(245,61,61,.4) !important;
    border-radius: 2px;
}

.target .word {
    background-color: rgba(61, 180, 61, 0.15) !important;
    border-radius: 2px;
}
.target .syms {
    background-color: rgba(61, 180, 61,.4) !important;
    border-radius: 2px;
}