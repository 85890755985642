.active-selector__container{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 16px;
    cursor: pointer;
    justify-content: center;
}

.active-selector__container:hover{
    background-color: white;
    border-radius: 8px;
}

.arrow__icon-active{
    transform: rotate(180deg);
}

.selector__container-active {
    background-color: white;
    border-radius: 8px;
}

.options{
    height: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 4px 24px #0910301A);
    border-radius: 8px;
    z-index: 100000;
    transform: translateY(10px) translateX(-30px);
    width: 140px;
    display: none;
}

.options-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 4px 24px #0910301A);
    border-radius: 8px;
    z-index: 100000;
    transform: translateY(10px) translateX(30px);
    width: 140px;
}

.options__active{
    display: flex;
}

.option{
    padding: 10px 32px;
}

.option:hover{
    background-color: #F7F9FB;
}

.selector__container-sharp{
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    background-color: white;
    width: 60px;
}