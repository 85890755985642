
.tippy-container{
  background-color: #7C93F7!important;
  cursor: default!important;
  width: 240px;
  padding: 10px 15px;
  border-radius: 8px!important;
}

.tippy-arrow{
  color: #7C93F7!important;
}

.tippy-title, .tippy-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tippy-title>span{
  font-family: Satoshi-Bold!important;
  font-size: 16px;
}

.tippy-description{
  font-family: Satoshi-Medium!important;
  font-size: 14px;
}

.tippy-footer{
  padding-top: 10px;
}

.tippy-btn{
  font-family: Satoshi-Bold!important;
  font-size: 14px;
  border: 1px solid #FFFFFF40;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer!important;
}