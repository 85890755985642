.premium-activated-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.premium-activated-modal__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  /* margin-bottom: 24px; */
}

.premium-activated-modal__crown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #7C93F7;
  border-radius: 12px;
  margin-bottom: 4px;
}

.premium-activated-modal__crown svg {
  width: 32px;
  height: 32px;
}

.premium-activated-modal__title {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 0;
  text-align: center;
}

.premium-activated-modal__message {
  font-size: 16px;
  color: #5C5E64;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 20px;
  font-family: 'Satoshi-Medium'!important;
}

.premium-activated-modal__actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.premium-activated-modal__actions .modal-action-button {
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.premium-activated-modal__actions .primary {
  background-color: #7C93F7;
  color: white;
  border: none;
}

.premium-activated-modal__actions .primary:hover {
  background-color: #6B84F0;
}

.premium-activated-modal__actions .secondary {
  background-color: #F5F7FA;
  color: #333;
  border: 1px solid #E2E8F0;
}

.premium-activated-modal__actions .secondary:hover {
  background-color: #EDF1F9;
} 