.table, .row__cell, .table__cell{
    --parent-width: calc(100vw - 300px - 80px);
}

.table{
    background-color: white;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    width: var(--parent-width);
}

.table__header{
    background-color: #F7F9FB;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 12px 12px 0px 0px;
    border-bottom: 1px solid var(--border-color);
}

.header__cell{
    color: #5C5E64;
    width: calc(var(--parent-width) / 5 - 40px);
}

.table__row{
    display: flex;
    padding: 20px;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    width: calc(100% - 40px);
    justify-content: space-between;
}

.table__row__last{
    border-bottom: none;
}

.row__cell{
    font-family: Satoshi-Medium!important;
    color: #080C1E;
    font-size: 16px;
    width: calc(var(--parent-width) / 5 - 40px);
    display: flex;
    align-items: center;
}

.row__actions{
    display: flex;
    justify-content: end;
    height: 40px;
}
.icon__container{
    border: 1px solid rgba(0,0,0,0);
    cursor: pointer;
}
.icon__container:hover>svg>path{
    stroke: #7C93F7;
}
.icon__container:hover{
    border-radius: 8px;
    border: 1px solid #7C93F7;
}

.apikey__container{
    display: flex;
    align-items: center;
    gap: 10px;
}