.speedometr__container{
    display: flex;
    flex-direction: column;
}

.speed__arrow{
    transform-origin: right;
    transform: translateY(-7px) translateX(9.5px) rotate(0deg);
}

.speedometr__text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.speedometr__text>span{
    font-family: Satoshi-Medium!important;
    font-size: 14px;
    color: #A6A8B0;
}