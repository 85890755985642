.premium-modal__crown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #7C93F7;
    border-radius: 8px;
}

.premium-modal__crown svg {
    width: 24px;
    height: 24px;
}

.premium-modal__header{
    display: flex;
    align-items: center;
    gap: 10px;
}

.premium-modal__title{
    color: #080C1E;
}

.premium-modal__description, .premium-modal__offer{
    color: #5C5E64;
    font-family: Satoshi-Regular!important;
}

.premium-modal__form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.premium-modal__actions{
    display: flex;
    gap: 10px;
    padding-top: 30px;
}

.modal-action-button{
    padding: 16px 12px!important;
    width: 260px;
}

.premium-modal__header2{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.premium-modal__title2{
    font-size: 32px;
    color: #080C1E;
}

.premium-modal__description2{
    font-family: Satoshi-Regular!important;
    font-size: 18px;
    color: #5C5E64;
    text-align: center;
}

.premium-modal__actions2{
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-action-button2{
    padding: 16px 0px!important;
}

.premium-modal__description3{
    text-align: center;
    font-family: Satoshi-Bold!important;
    font-size: 18px;
}

.premium-modal__header3{
    justify-content: center;
}

.feedback__container{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.feedback-with-message{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.feedback-message{
    font-family: Satoshi-Regular!important;
    font-size: 14px;
    color: #5C5E64;
}

.premium-modal__error-message {
    color: #ff4d4f;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 12px;
}

.premium-modal{
    overflow: visible;
}

.ant-modal-body{
    overflow-x: visible;
}
.ant-modal-content{
    overflow-x: visible;
}