body, html {
    background-color: rgba(247, 249, 251, 1);
}

.extension-login__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.extension-login__centered{
    width: 600px;
    height: 100vh;
    background-color: white;
}

.extension-login__content{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.information{
    font-family: Satoshi-Bold!important;
    color: rgba(8, 12, 30, 1)!important;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.information>span{
    color: rgba(8, 12, 30, 1)!important;
}

.information__container>span{
    font-size: 18px;
    font-family: Satoshi-Medium;
    color: rgba(92, 94, 100, 1)!important;
}

.information__container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.actions__container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 60px;
    width: 270px;
}

.actions__centered{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}