.premium-modal__email-container{
  display: flex;
  flex-direction: column;
  gap: 3px;
}


.premium-modal__email-note{
  margin: 0px;
  font-size: 14px;
  font-family: Satoshi-Regular!important;
}
.premium-modal__email-note>a{
  color: #7C93F7;
  text-decoration: none;
  text-decoration-line: underline;
}