.chart__container{
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
    border: 1px solid var(--border-color);
}

.chart__header{
    color: #5C5E64;
    font-size: 16px;
    font-family: Satoshi-Medium!important;
}

.tooltip__container{
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-icon__container{
    position: absolute;
    z-index: -1;
}

.tooltip__text{
    color: white;
    z-index: 1;
    transform: translateY(-7px);
    font-size: 12px;
}

.no-data__container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}