.tooltip__child{
    display: none;
}

.tooltip__parent{
    font-family: Satoshi-Light!important;
    margin-right: 3px;
}

.bad__tooltip{
    background-image: url(Tooltip.png);
}

.good__tooltip{
    background-image: url(Tooltip_green.png);
}

.medium__tooltip{
    background-image: url(Tooltip_yellow.png);
}

.tooltip__parent-red{
    background-color: #F9EDED;
}

.tooltip__parent-green{
    background-color: #f2f9f2;
}

.tooltip__parent-yellow{
    background-color: #F5E7BE;
}

.segmentation-tooltip{
    z-index: 100000000;
}

.div__tooltip{
    position: relative;
    width: 0px;
    height: 0px;
    display: inline;
}