.sidebar__container{
    width: 300px;
    height: calc(100vh);
}

.slot__api{
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-left: 2px solid #EDF1F9!important;
    margin-left: 20px;
}

.slot__container{
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 10px;
    border-left: 4px solid #7C93F7;
    background-color: #F7F9FB;
    cursor: pointer;
    color: #7C93F7!important;
}

.slot-api__active{
    border-left: 2px solid #7C93F7!important;
}

.slot__disabled{
    border-left: 4px solid rgba(0,0,0,0);
    background-color: white!important;
    color: var(--text-primary-color);
}

.slot__text{
    color: #7C93F7!important;
    width: 100%;
}

.slot__text__disabled{
    color: var(--text-primary-color)!important;
}

.slots{
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
}

.sidebar__logo{
    padding: 20px;
}

.sidebar__content{
    background-color: white;
    border: 1px solid var(--border-color);
    height: 100%;
    width: 298px;
}

.sidebar__button{
    margin: 20px;
    width: calc(100% - 40px);
}

.api__sidebar{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rotated{
    transform: rotate(180deg);
}

.rotated-back{
    transform: rotate(0deg);
}

.sidebar__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.logo__container-f{
    padding: 25px;
}

.progress-sidebar__container{
    padding: 20px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sb__text{
    color: #5C5E64;
    font-family: Satoshi-Medium!important;
}