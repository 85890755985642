.mobile-screen{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
}

.mobile-screen__content-header{
  font-size: 24px;
  font-weight: 600;
  color: #080C1E;
  text-align: center;
  width: 290px;
}

.mobile-screen__content-text{
  font-size: 16px;
  font-weight: 400;
  color: #5C5E64;
  text-align: center;
  font-family: Satoshi-Medium!important;
}

.mobile-screen__content{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.mobile-screen__content-image{
  margin-top: 10px;
}