.avatar__container{
    display: inline-flex;
    gap: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    align-items: center;
    cursor: pointer;
    padding: 8px;
}

.avatar-active__container{
    border-radius: 8px;
    background-color: #F7F9FB;
}

.avatar__container:hover{
    background-color: #F7F9FB;
    border-radius: 8px;
}

.avatar__content{
    display: flex;
    gap: 10px;
    align-items: center;
}

.avatar{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #080C1E;
    background-color: #82DED9;
    border-radius: 100px;
    font-size: 14px;
}

.avatar__menu{
    position: absolute;
    transform: translateY(-50px) translateX(-10px);
    display: none;
    flex-direction: column;
    filter: drop-shadow(0px 4px 24px #0910301A);
    background-color: white;
    border-radius: 8px;
    border: 1px solid #EDF1F9;
    width: calc(191px - 32px);
    padding: 4px 16px;
    z-index: 111;
}

.avatar-menu__item{
    padding: 9px;
    color: #5C5E64;
    font-size: 14px;
}

.avatar-menu__active{
    display: flex;
}

.red{
    color: #F18D8D;
}