.current-plan__container, .stats{
    --parent-width: calc(100vw - 298px - 80px - 40px);
}

.stats{
    width: calc(var(--parent-width) + 45px);
}
.current-plan__container{
    background-color: white;
    padding: 24px;
    border-radius: 12px;
    width: var(--parent-width);
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    align-items: end;
    width: var(--parent-width);
}

.current-plan__content{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.current-plan__words{
    display: flex;
    flex-direction: column;
    gap: 00px;
}

.current-plan__text{
    font-family: Satoshi-Light!important;
    font-size: 16px;
    color: #5C5E64;
}

.current-plan__words-words{
    font-family: Satoshi-Bold!important;
    font-size: 24px;
    color: #080C1E;
}

.progress__plan{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.words__used{
    font-family: Satoshi-Light!important;
}

.get-more-words-btn{
    width: fit-content!important;
    padding: 16px 48px!important;
}