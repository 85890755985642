body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, head, html{
  scroll-behavior: smooth;
}

*{
  font-family: Public Sans;
  /* color: #0E0C0D; */
  --base-width: 1200px
}

*::-webkit-scrollbar {
  display: none;
}

button{
  outline: none;
  border: none;
  padding: 10px 30px;
  background-color: #0E0C0D;
  color: #F2F4F5;
  border-radius: 100px;
  font-size: 16px;
  min-height: 37px;
}

button.white{
  background-color: #F2F4F5;
  color: #0E0C0D;
}
a{
  color: black;
}
a.white{
  color: white;
  background-color: none!important;
  background: none!important;
}

size100 {
  width: 100%;
  height: 100%;
}

.inserted-txt {
  background-color: #a9d2a3;

}
.removed-txt {
  background-color: #f1b6b6;
}
.editable-britishizer * {
  font-family: Satoshi-Regular !important;
}